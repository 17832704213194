// color variants
@import 'themes-vars.module.scss';

// third-party
@import ' ~react-perfect-scrollbar/dist/css/styles.css';
@import ' ~slick-carousel/slick/slick.css';
@import ' ~slick-carousel/slick/slick-theme.css';

@import ' ~react-18-image-lightbox/style.css';
@import 'react-responsive-carousel/lib/styles/carousel.min.css';

// ==============================|| LIGHT BOX ||============================== //
.slick-arrow:before {
    color: $grey500;
}

// ==============================|| LIGHT BOX ||============================== //
.fullscreen .react-images__blanket {
    z-index: 1200;
}

// ==============================|| APEXCHART ||============================== //

.apexcharts-legend-series .apexcharts-legend-marker {
    margin-right: 8px;
}

// ==============================|| PERFECT SCROLLBAR ||============================== //

.scrollbar-container {
    .ps__rail-y {
        &:hover > .ps__thumb-y,
        &:focus > .ps__thumb-y,
        &.ps--clicking .ps__thumb-y {
            background-color: $grey500;
            width: 5px;
        }
    }
    .ps__thumb-y {
        background-color: $grey500;
        border-radius: 6px;
        width: 5px;
        right: 0;
    }
}

.scrollbar-container.ps,
.scrollbar-container > .ps {
    &.ps--active-y > .ps__rail-y {
        width: 5px;
        background-color: transparent !important;
        z-index: 999;
        &:hover,
        &.ps--clicking {
            width: 5px;
            background-color: transparent;
        }
    }
    &.ps--scrolling-y > .ps__rail-y,
    &.ps--scrolling-x > .ps__rail-x {
        opacity: 0.4;
        background-color: transparent;
    }
}

// ==============================|| ANIMATION KEYFRAMES ||============================== //

@keyframes wings {
    50% {
        transform: translateY(-40px);
    }
    100% {
        transform: translateY(0px);
    }
}

@keyframes blink {
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes bounce {
    0%,
    20%,
    53%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }
    40%,
    43% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -5px, 0);
    }
    70% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -7px, 0);
    }
    80% {
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }
    90% {
        transform: translate3d(0, -2px, 0);
    }
}

@keyframes slideY {
    0%,
    50%,
    100% {
        transform: translateY(0px);
    }
    25% {
        transform: translateY(-10px);
    }
    75% {
        transform: translateY(10px);
    }
}

@keyframes slideX {
    0%,
    50%,
    100% {
        transform: translateX(0px);
    }
    25% {
        transform: translateX(-10px);
    }
    75% {
        transform: translateX(10px);
    }
}

// ==============================|| ANIMATION KEYFRAMES ||============================== //

.preBuildDashBoard-slider {
    overflow: hidden;
    .slider {
        .slide {
            opacity: 0.5;
            display: flex;
            justify-content: center;
            .custom-slider {
                gap: 40px;
            }
            &.selected {
                opacity: 1;
                .custom-slider {
                    display: flex;
                    flex-direction: column;
                }
            }
            &:not(.selected) {
                transform: scale(0.7);
                transform-origin: top;
            }
        }
    }
}

.project-info {
    .project-slider {
        .slick-list {
            padding: 0 !important;
        }
    }
    .slick-slide {
        opacity: 0.05;
    }
    .slick-active {
        opacity: 0.2;
    }
    .slick-current {
        opacity: 1 !important;
        .MuiTypography-root {
            color: $primaryMain;
        }
    }
}

.ril__inner {
    direction: ltr;
}

.ReactModal__Overlay {
    z-index: 99999 !important;
}

/* ==============================|| MYBNB CUSTOM RULES ||============================== */
.fc-license-message {
    display: none;
}
.mobile-calendar-table {
    width: 100%;
    table-layout: fixed;
    border-collapse:separate; 
}
.mobile-calendar-table tr {
}
.mobile-calendar-table td {
    padding-bottom: .5em;
    padding-top: .5em;
    border-bottom: 1px solid #ddd;
}
.mobile-calendar-appartement {
    font-size: .6em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 30%;
    width: 30%;
}
.mobile-calendar-progressbar {
    font-size: .6em;
    width: 60%;
    padding-left: 1em;
    padding-right: 1em;
}
.mobile-calendar-percent {
    font-size: .6em;
    width: 10%;
    text-align: center;
}
.mobile-calendar-appartement.title, .mobile-calendar-progressbar.title {
    font-size: .8em;
}
.mobile-calendar-progressbar ul {
    margin: 0;
    padding: 0;
    display: flex;
}
.mobile-calendar-progressbar ul > li {
    display: inline-block;
    width: 100%;
    background-color: #fd5c63; 
    height: 10px;
    padding: 0;
    margin: 0;
}
.mobile-calendar-progressbar ul > li.unbooked {
    background-color: #ececec;
}
.mobile-calendar-progressbar ul > li:first-child {
    border-top-left-radius: 10px!important;
    border-bottom-left-radius: 10px!important;
}
.mobile-calendar-progressbar ul > li:last-child {
    border-top-right-radius: 10px!important;
    border-bottom-right-radius: 10px!important;
}
.fc-daygrid-event-harness {
    top: 0!important;
}
.fc-daygrid-day-events {
    max-height: 1em;
}
a.fc-daygrid-event.fc-event.fc-event-start {
    border-top-left-radius: 50px!important;
    border-bottom-left-radius: 50px!important;
}
a.fc-daygrid-event.fc-event.fc-event-end {
    border-top-right-radius: 50px!important;
    border-bottom-right-radius: 50px!important;
}
button.fc-BackwardButton-button, button.fc-ForwardButton-button {
    background-color: #fff;
    color: #337ab7;
    border-radius: 5px!important;
}
.fc-license-message {
    display: none!important;
}
.fc-timeline-event {
    border-radius: 40px!important;
    height: 30px;
    margin-top: 12px;
    overflow: hidden;
}
.fc-highlight {
    background-color: #ff8f9670!important;
}
.fc .fc-bg-event {
    opacity: 1!important;
    // padding: 2px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    font-size: 0.8em;
}
.fc .fc-timeline-slot-frame {
    justify-content: center!important;
}
.fc .fc-resource-timeline .fc-resource-group:not([rowspan]) {
    background: #ff76761f;
}
.fc .fc-timeline-now-indicator-line {
    border: 1.5px solid red!important;
    background-color: red;
}
// .resource-content {
//     position: absolute;
//     height: 100%;
//     display: flex;
//     align-items: center;
//     top: 0;
//     left: 10;
// }
.fc-event-container[style] {
   height: 25px !important;
}
.fc-timeline-slot {
    cursor: pointer;
}

.fc-datagrid-cell-cushion.fc-scrollgrid-sync-inner {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
}
.fc-theme-standard td, .fc-theme-standard th {
    border: 1px solid #ddd;
}
.fc .fc-timeline-slot-cushion {
    color: #333;
    text-transform: capitalize;
}
.fc-datagrid-cell-frame {min-height: 50px !important}
.fc-timeline-lane-frame {min-height: 50px !important}
div.fc-view-harness.fc-view-harness-active > div > table > thead > tr > td:nth-child(1) > div > div > table > tbody > tr > th:nth-child(1) > div {min-height: 35px !important}
div.fc-view-harness.fc-view-harness-active > div > table > thead > tr > td:nth-child(1) > div > div > table > tbody > tr > th:nth-child(2) > div {min-height: 35px !important}

.fc-button {
    background-color: transparent!important;
    color: #2C3E50!important;
}
.fc-button-group .fc-button:first-child {
    border-top-left-radius: 10px!important;
    border-bottom-left-radius: 10px!important;
}
.fc-button-group .fc-button:last-child {
    border-top-right-radius: 10px!important;
    border-bottom-right-radius: 10px!important;
}
span.fc-icon {
    display: none;
}
span.fc-datagrid-expander.fc-datagrid-expander-placeholder {
    display: none;
}
div#checkin-validation-original {
    max-height: 400px;
    overflow: auto;
    margin-bottom: 1em;
    border: 1px solid #ddd;
    border-radius: 10px!important;
}
.sendInstructionsMessage th, .sendInstructionsMessage td {
    padding: 5px;
}
#sentMessageType {
    font-size: 0.7em;
}
.hide-template-message {
    display: none;
}
.toggle-template-message {
    cursor: pointer;
    font-weight: bold;
}
button.select-template {
    border-radius: 50%!important;
    padding: 10px;
    aspect-ratio: 1/1;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
}
.template-message, .template-message-actions {
    display: none;
}
.btn-success {
    background-color: #28a745 !important;
    border-color: #28a745 !important;
}

#changePriceSide {
    position: fixed;
    display: none;
    padding: 50px 5px 5px 5px;
    width: 300px;
    height: 100vh;
    background-color: white;
    right: -300px;
    top: 0;
    z-index: 10;
    box-shadow: -5px 0px 6px 1px rgba(171,171,171,0.8);
    transition: right 0.5s;
}
#closePriceSide {
    text-align: right;
    font-size: 1.4em;
    font-weight: bold;
}
#closePriceSide > span {
    cursor: pointer;
}